import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import FurtherAssessment from "components/Table/FurtherAssessment.js";
import FormControl from "@material-ui/core/FormControl";
import ReferralAssessmentsTable from "./ReferralAssessmentsTable";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from "@material-ui/core/MenuItem";
import {parseDate} from "../../util/DateUtils.js"
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from '@date-io/date-fns';
import {enGB} from 'date-fns/locale'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {format, isValid} from "date-fns";
import {ArrowDropDown} from "@material-ui/icons";

const ReadOnlyTextFieldComponent = (props) => (
    <TextField {...props}
               fullWidth
               InputLabelProps={{shrink: true}}
               InputProps={{
                 readOnly: true
               }}/>
);

const TextFieldComponent = (props) => (
    <TextField {...props}
               fullWidth
               InputLabelProps={{shrink: true}}/>
);

const REQUIRED_INCOME_EXPENDITURE_FIELDS = {
    'income': ['Salary', 'UC/Benefit', 'Health benefit', 'Tax credits', 'Pension credits'],
    'expenditure': ['Rent', 'Telephone bill', 'Council tax', 'Gas', 'Electricity', 'Water', 'Travel'],
    'debt': ['UC advance', 'Credit card', 'Council tax arrears', 'Rent arrears']
}

const VALIDATION = {
    "status": (value, assessment) => ((!value || value.length === 0) && 'Status must be set'),
    "nameOfAdvisor": (value, assessment) => ((!value || value.length === 0) && 'Name of advisor must be set'),
    "dateOfAssessment": (value, assessment) => {
        if (!value || typeof value === 'string' && value.length === 0)
            return "Date of assessment must be set"
        else if (value instanceof Date && !isValid(value))
            return "Invalid date"
        else
            return null
    },
    "numberOfParcels": (value, assessment) => {
        if (assessment.status && assessment.status === "REJECTED")
            return value && value.length > 0 && "No parcels are permitted if further food aid is rejected"
        else if (!value || value.length === 0)
            return "Number of parcels must be set"
        else if (typeof value === 'string' && !value.match(/^\d+$/))
            return "Value must be a number"
        else if (parseInt(value) < 0)
            return "Value must be positive"
        else if (parseInt(value) > 6)
            return "A maximum of 6 parcels is permitted"
        else
            return null
    },
    "currentSituation": (value, assessment) => ((!value || value.length === 0) && 'Current situation must be set'),
    "groupsMember": (value, assessment) => null,
    "organisationsSupporting": (value, assessment) => ((!value || value.length === 0) && 'Organisations supporting must be set'),
    "otherSupport": (value, assessment) => ((!value || value.length === 0) && 'Other routes of support must be set'),
    "income": (value, assessment) => {
        if (!value || value.length === 0)
            return [{'label': 'Income must be set'}]
        else
            return value.map(inc => {
                return {
                    'label': (!inc['label'] || inc['label'].length === 0) && 'Income must be set',
                    'amount': (!inc['amount'] || inc['amount'].length === 0) && 'Amount must be set' || isNaN(inc['amount']) && 'Amount must be a valid number'
                }
            })
    },
    "expenditure": (value, assessment) => {
        if (!value || value.length === 0)
            return [{'label': 'Expenditure must be set'}]
        else
            return value.map(inc => {
                return {
                    'label': (!inc['label'] || inc['label'].length === 0) && 'Expenditure must be set',
                    'amount': (!inc['amount'] || inc['amount'].length === 0) && 'Amount must be set' || isNaN(inc['amount']) && 'Amount must be a valid number'
                }
            })
    },
    "debt": (value, assessment) => {
        if (!value || value.length === 0)
            return [{'label': 'Expenditure must be set'}]
        else
            return value.map(inc => {
                return {
                    'label': (!inc['label'] || inc['label'].length === 0) && 'Expenditure must be set',
                    'amount': (!inc['amount'] || inc['amount'].length === 0) && 'Amount must be set' || isNaN(inc['amount']) && 'Amount must be a valid number'
                }
            })
    },
    "incomeExpenditureComments": (value, assessment) => null,
    "issueResolution": (value, assessment) => ((!value || value.length === 0) && 'Issue resolution must be set'),
    "predictedDateOfSuccess": (value, assessment) =>  {
        if (!value || typeof value === 'string' && value.length === 0)
            return "Date of predicted successful outcome must be set"
        else if (value instanceof Date && !isValid(value))
            return "Invalid date"
        else
            return null
    },
    "referredTo": (value, assessment) => ((!value || value.length === 0) && 'Referred to must be set'),
    "furtherComments": (value, assessment) => null
}

const INCOME_EXPENDITURE_VALIDATION = {
    "label": (value, assessment) => ((!value || value.length === 0) && 'Value must be set'),
    "amount": (value, assessment) => ((!value || value.length === 0) && 'Amount must be set') || isNaN(value) && 'Amount must be a valid number'

}

class FurtherAssessmentMonthly extends Component {
    constructor(props) {
        super(props);
        this.editFurtherAssessment = this.editFurtherAssessment.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.changeIncomeExpenditureAmount = this.changeIncomeExpenditureAmount.bind(this)
        this.formatIncomeExpenditureAmount = this.formatIncomeExpenditureAmount.bind(this)
        this.changeIncomeExpenditureLabel = this.changeIncomeExpenditureLabel.bind(this)
        this.renderIncomeExpenditure = this.renderIncomeExpenditure.bind(this)
        this.calculateTotalIncomeExpenditureAmount = this.calculateTotalIncomeExpenditureAmount.bind(this)
        this.addIncomeExpenditure = this.addIncomeExpenditure.bind(this)
        this.removeIncomeExpenditure = this.removeIncomeExpenditure.bind(this)
        this.addIncomeExpenditure = this.addIncomeExpenditure.bind(this)
        this.resetEditedFurtherAssessment = this.resetEditedFurtherAssessment.bind(this)
        this.renderPreviousAssessments = this.renderPreviousAssessments.bind(this)
        this.viewPreviousAssessment = this.viewPreviousAssessment.bind(this)

        this.state = {
            editedAssessment: {},
            error: {},
            displayMessage: false,
            edit: false,
            viewFurtherAssessmentDialogId: null
        };
    }

    componentDidMount() {
        this.resetEditedFurtherAssessment();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.readOnly !== this.props.readOnly || this.state.edit !== prevState.edit || this.props.id !== prevProps.id || this.props.referral?.id !== prevProps.referral?.id) {
            this.setState({editedAssessment: this.resetEditedFurtherAssessment()});
        }
    }

    resetEditedFurtherAssessment() {
        if (!this.props.readOnly && this.state.edit) {
            return Object.assign({}, this.props.assessment);
        }
        else if (this.props.id === null && this.props.referral) {
            var latest = this.props.referral.furtherAssessments && this.props.referral.furtherAssessments[0] || this.props.referral;
            var referralType = this.props.referral.referralType
            return {
                groupsMember: latest.groupsMember || [],
                incomeExpenditureReviewConducted: true,
                parcelFrequency: referralType === 'READY_MEALS' && 'WEEKLY' || 'MONTHLY',
                policy: 'MONTHLY_PARCELS',
                income: REQUIRED_INCOME_EXPENDITURE_FIELDS['income'].map(label => {return { 'label': label }}),
                expenditure: REQUIRED_INCOME_EXPENDITURE_FIELDS['expenditure'].map(label => {return { 'label': label }}),
                debt: REQUIRED_INCOME_EXPENDITURE_FIELDS['debt'].map(label => {return { 'label': label }}),
                dateOfAssessment: format(new Date(), 'dd-MM-yyyy')
            };
        }
        return {};
    }

    renderPreviousAssessments() {
        return (
            <React.Fragment>
                <FurtherAssessment
                    open={this.state.viewFurtherAssessmentDialogId}
                    readOnly={true}
                    onClose={() =>this.setState({ viewFurtherAssessmentDialogId: null})}
                    getOptions={this.props.getOptions}
                    id={this.state.viewFurtherAssessmentDialogId}
                    referral={this.state.viewFurtherAssessmentDialogId && this.props.referral}
                    assessment={this.state.viewFurtherAssessmentDialogId && this.props.referral?.furtherAssessments?.find((assessment) => assessment.id === this.state.viewFurtherAssessmentDialogId)}/>
                <ReferralAssessmentsTable tableHeaderColor="grayColor"
                                          tableHead={{
                                              "ID": assessment => assessment.id,
                                              "Status": assessment => assessment.status,
                                              "Name of advisor": assessment => assessment.nameOfAdvisor,
                                              "Date of assessment": assessment => assessment.dateOfAssessment,
                                              "Number of parcels / Date of food aid expiry": assessment => assessment.numberOfParcels || assessment.dateOfFoodAidExpiry
                                          }}
                                          referral={this.props.referral}
                                          assessments={this.props.referral?.furtherAssessments || []}
                                          viewFurtherAssessment={this.viewPreviousAssessment}/>
            </React.Fragment>)
    }

    viewPreviousAssessment(referralId, furtherAssessmentId) {
        this.setState({viewFurtherAssessmentDialogId: furtherAssessmentId})
    }

    changeValue(field, value) {
        var editedAssessment = this.state.editedAssessment;
        var error = this.state.error;
        editedAssessment[field] = value;
        error[field] = VALIDATION[field] && VALIDATION[field](value, editedAssessment);
        if (field === 'status')
            error['numberOfParcels'] = VALIDATION['numberOfParcels'](editedAssessment.numberOfParcels, editedAssessment)

        var displayMessage = Object.values(error).some(err => typeof err === 'string' && err || Array.isArray(err) && err.some(incExpErr => Object.values(incExpErr).some(e => e)))
        this.setState({editedAssessment: editedAssessment, error: error, displayMessage: displayMessage});
    }

    changeIncomeExpenditureLabel(field, idx, value) {
        var editedAssessment = this.state.editedAssessment;
        var error = this.state.error;

        var editedField = editedAssessment[field] || [];
        var editedFieldElement = editedField[idx] || {};
        editedFieldElement['label'] = value;
        editedField[idx] = editedFieldElement;
        editedAssessment[field] = editedField;

        var fieldError = error[field] || []
        var elementError = fieldError[idx] || []
        elementError['label'] = INCOME_EXPENDITURE_VALIDATION['label'] && INCOME_EXPENDITURE_VALIDATION['label'](value, editedAssessment)
        fieldError[idx] = elementError
        error[field] = fieldError

        var displayMessage = Object.values(error).some(err => typeof err === 'string' && err || Array.isArray(err) && err.some(incExpErr => Object.values(incExpErr).some(e => e)))
        this.setState({editedAssessment: editedAssessment, error: error, displayMessage: displayMessage});
    }

    changeIncomeExpenditureAmount(field, idx, value) {
        var editedAssessment = this.state.editedAssessment;
        var error = this.state.error;

        var editedField = editedAssessment[field] || [];
        var editedFieldElement = editedField[idx] || {};
        editedFieldElement['amount'] = value;
        editedField[idx] = editedFieldElement;
        editedAssessment[field] = editedField;

        var fieldError = error[field] || []
        var elementError = fieldError[idx] || []
        elementError['amount'] = INCOME_EXPENDITURE_VALIDATION['amount'] && INCOME_EXPENDITURE_VALIDATION['amount'](value, editedAssessment)
        fieldError[idx] = elementError
        error[field] = fieldError

        var displayMessage = Object.values(error).some(err => typeof err === 'string' && err || Array.isArray(err) && err.some(incExpErr => Object.values(incExpErr).some(e => e)))
        this.setState({editedAssessment: editedAssessment, error: error, displayMessage: displayMessage});
    }

    formatIncomeExpenditureAmount(field, idx, value) {
        var editedAssessment = this.state.editedAssessment;
        var editedField = editedAssessment[field] || [];
        var editedFieldElement = editedField[idx] || {};
        editedFieldElement['amount'] = value && value.length > 0 && !isNaN(value) && parseFloat(value).toFixed(2) || value;
        editedField[idx] = editedFieldElement;
        editedAssessment[field] = editedField;
        this.setState({editedAssessment: editedAssessment});
    }

    editFurtherAssessment() {
        var error = this.state.error;
        let editedAssessment = this.state.editedAssessment;
        Object.keys(VALIDATION).forEach(field => error[field] = VALIDATION[field] && VALIDATION[field](editedAssessment[field], editedAssessment))
        if (Object.values(error).some(err => typeof err === 'string' && err || Array.isArray(err) && err.some(incExpErr => Object.values(incExpErr).some(e => e)))) {
            this.setState({displayMessage: true, error: error})
        }
        else if (this.props.id) {
            this.props.editFurtherAssessment(this.props.id, editedAssessment)
                .then(() => {
                    this.setState({
                        editedAssessment: {},
                        error: {},
                        displayMessage: false,
                        edit: false
                    })
                });
        }
        else {
            this.props.createFurtherAssessment(this.props.referral.id, editedAssessment)
                .then(() => {
                    this.setState({
                        editedAssessment: {},
                        error: {},
                        displayMessage: false,
                        edit: false
                    })
                });
        }
    }

    addIncomeExpenditure(field) {
        var assessment = this.state.editedAssessment;
        var fieldValue = assessment[field] || [];
        fieldValue[fieldValue.length] = {};
        assessment[field] = fieldValue;
        this.setState({editedAssessment: assessment})
    }

    removeIncomeExpenditure(field, idx) {
        var assessment = this.state.editedAssessment;
        var fieldValue = assessment[field] || [];
        var required = REQUIRED_INCOME_EXPENDITURE_FIELDS[field].includes(fieldValue[idx]['label'])
        if (!required) {
            fieldValue.splice(idx, 1);
            assessment[field] = fieldValue;
            this.setState({editedAssessment: assessment})
        }
    }

    renderIncomeExpenditure(assessment, field, editing) {
        var fieldValue = assessment[field] || [];
        if (fieldValue.length === 0) {
            fieldValue[0] = {};
        }

        return fieldValue.map((fieldElement, idx) => {
            var fieldError = this.state.error[field] && this.state.error[field][idx] || {}
            var required = REQUIRED_INCOME_EXPENDITURE_FIELDS[field].includes(fieldElement.label)
            return (<React.Fragment>
                      <GridItem xs={10} sm={12} md={6}>
                        <TextField id={field + "Label" + idx}
                                   InputProps={{readOnly: !editing || required}}
                                   InputLabelProps={{shrink: true}}
                                   fullWidth
                                   value={fieldElement.label || ''}
                                   error={fieldError['label']}
                                   helperText={fieldError['label']}
                                   onChange={ev => this.changeIncomeExpenditureLabel(field, idx, ev.target.value)}/>
                      </GridItem>
                      <GridItem xs={10} sm={12} md={4}>
                        <TextField id={field + "Amount" + idx}
                                   InputLabelProps={{shrink: true}}
                                   fullWidth
                                   value={fieldElement.amount || ''}
                                   InputProps={{
                                       readOnly: !editing,
                                       startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                   }}
                                   error={fieldError['amount']}
                                   helperText={fieldError['amount']}
                                   onChange={ev => this.changeIncomeExpenditureAmount(field, idx, ev.target.value)}
                                   onBlur={ev => this.formatIncomeExpenditureAmount(field, idx, ev.target.value)}/>
                      </GridItem>
                      {!required && (<GridItem xs={10} sm={12} md={2}>
                                       <IconButton aria-label="remove"
                                                   size="small"
                                                   disabled={!editing}
                                                   onClick={() => this.removeIncomeExpenditure(field, idx)}>
                                         <DeleteIcon/>
                                       </IconButton>
                                     </GridItem>) || (<GridItem xs={10} sm={12} md={2}/>)}
                    </React.Fragment>)
        })
    }

    calculateTotalIncomeExpenditureAmount(assessment, field) {
        var fieldValue = assessment[field] || [];
        return fieldValue.reduce((accumulator, currentValue) => {
            return accumulator + (parseFloat(currentValue.amount) || 0.0)
        }, 0.0).toFixed(2)
    }

    render() {
        const { classes } = this.props;
        const editing = this.props.id === null && this.props.referral || !this.props.readOnly && this.state.edit;
        const editedAssessment = (editing && this.state.editedAssessment) || this.props.assessment || {}
        return editedAssessment && (
            <Dialog open={this.props.referral}
                    onClose={() => {
                        this.setState({
                            editedAssessment: {},
                            errors: {},
                            displayMessage: null,
                            edit: false
                        })
                        this.props.onClose();
                    }}
                    fullWidth
                    maxWidth="md">
                <DialogTitle id="edit-further-assessment-title">
                    {`${editing && (this.props.id && "Edit" || "Create") || "View"} Further Assessment${this.props.id && (": " + this.props.id) || ""}`}
                </DialogTitle>
                <DialogContent>
                    <form>
                        <GridContainer>
                            <GridItem md={12}>
                                <Typography variant="body2" color="textSecondary" align="left">Please review any previous further assessments, and follow up on any actions where necessary</Typography>
                             </GridItem>
                            <GridItem md={12}>
                                {this.props.referral && !this.props.id && this.renderPreviousAssessments()}
                             </GridItem>
                            <GridItem md={11}/>
                            {!this.props.readOnly && this.props.id && (<React.Fragment>
                                <GridItem md={1}>
                                    <Tooltip title="Edit further assessment">
                                        <IconButton aria-label="expand row"
                                                    size="large"
                                                    disabled={this.state.edit}
                                                    onClick={() => this.setState({edit: !this.state.edit})}>
                                            <EditOutlinedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </GridItem>
                            </React.Fragment>)}
                            <GridItem xs={10} sm={12} md={4}>
                                <TextField id="nameOfAdvisor"
                                           label="Name of advisor"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={editedAssessment.nameOfAdvisor || ''}
                                           error={this.state.error.nameOfAdvisor}
                                           helperText={this.state.error.nameOfAdvisor}
                                           onChange={ev => this.changeValue("nameOfAdvisor", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                    <KeyboardDatePicker
                                        key={this.props.id + editing && 'edit' || ''}
                                        value={editedAssessment.dateOfAssessment && parseDate(editedAssessment.dateOfAssessment) || null}
                                        autoOk
                                        clearable
                                        label="Date of assessment"
                                        variant="inline"
                                        onChange={(date) => this.changeValue("dateOfAssessment", isValid(date) && format(date, 'dd-MM-yyyy') || date)}
                                        disableFuture
                                        error={this.state.error.dateOfAssessment}
                                        helperText={this.state.error.dateOfAssessment}
                                        TextFieldComponent={!editing && ReadOnlyTextFieldComponent || TextFieldComponent}
                                        format="dd-MM-yyyy"/>
                                </MuiPickersUtilsProvider>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}/>
                            <GridItem xs={10} sm={12} md={8}>
                                <TextField id="currentSituation"
                                           label="Description of guests' current situation"
                                           InputLabelProps={{shrink: true}}
                                           multiline
                                           rows={3}
                                           rowsMax={Infinity}
                                           fullWidth
                                           value={editedAssessment.currentSituation || ''}
                                           error={this.state.error.currentSituation}
                                           helperText={this.state.error.currentSituation}
                                           InputProps={{
                                               readOnly: !editing,
                                               endAdornment: (<InputAdornment position="end">
                                                   <Tooltip classes={{tooltip: classes.tooltip}}
                                                            title="To access food aid guests should be in a financial crisis. For guests who are shielding but financially stable, please refer to a Hubs Coordinator for guidance.">
                                                       <HelpOutlineIcon/>
                                                   </Tooltip>
                                               </InputAdornment>)
                                           }}
                                           onChange={ev => this.changeValue("currentSituation", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}/>
                            <GridItem xs={10} sm={12} md={8}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="groupsMember" shrink={true}>Groups</InputLabel>
                                    <Select id="groupsMember"
                                            name="groupsMember"
                                            multiple
                                            readOnly={!editing}
                                            IconComponent={() => <ArrowDropDown style={!editing && { display: "none" } || {}} />}
                                            value={editedAssessment.groupsMember || []}
                                            onChange={ev => this.changeValue("groupsMember", ev.target.value)}>
                                        {this.props.getOptions('GROUPS').map(option => (<MenuItem value={option.value}
                                                                                                  disabled={!option.active}>{option.value}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}/>
                            <GridItem xs={10} sm={12} md={8}>
                                <TextField id="organisationsSupporting"
                                           label="Is this guest being supported with Advice/Guidance by another org/advisor?"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           error={this.state.error.organisationsSupporting}
                                           value={editedAssessment.organisationsSupporting || ''}
                                           helperText={this.state.error.organisationsSupporting}
                                           onChange={ev => this.changeValue("organisationsSupporting", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}/>
                            <GridItem xs={10} sm={12} md={8}>
                                <TextField id="otherSupport"
                                           label="Which other routes of support have been explored?"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           error={this.state.error.otherSupport}
                                           value={editedAssessment.otherSupport || ''}
                                           helperText={this.state.error.otherSupport}
                                           onChange={ev => this.changeValue("otherSupport", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}/>
                            <GridItem xs={10} sm={12} md={4}>
                                <GridContainer>
                                    <GridItem xs={10} sm={12} md={12}>
                                        <InputLabel shrink={true}>Income</InputLabel>
                                    </GridItem>
                                    {this.renderIncomeExpenditure(editedAssessment, "income", editing)}
                                    <GridItem xs={10} sm={12} md={6}>
                                        <IconButton aria-label="add"
                                                    size="small"
                                                    disabled={!editing}
                                                    onClick={() => this.addIncomeExpenditure("income")}>
                                            <AddCircleOutlineOutlinedIcon/>
                                        </IconButton>
                                    </GridItem>
                                    <GridItem xs={10} sm={12} md={4}>
                                      <TextField id={"IncomeTotalAmount"}
                                                 InputLabelProps={{shrink: true}}
                                                 fullWidth
                                                 value={this.calculateTotalIncomeExpenditureAmount(editedAssessment, "income")}
                                                 InputProps={{
                                                     readOnly: true,
                                                     startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                 }}/>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}>
                                <GridContainer>
                                    <GridItem xs={10} sm={12} md={12}>
                                        <InputLabel shrink={true}>Expenditure</InputLabel>
                                    </GridItem>
                                    {this.renderIncomeExpenditure(editedAssessment, "expenditure", editing)}
                                    <GridItem xs={10} sm={12} md={6}>
                                        <IconButton aria-label="add"
                                                    size="small"
                                                    disabled={!editing}
                                                    onClick={() => this.addIncomeExpenditure("expenditure")}>
                                            <AddCircleOutlineOutlinedIcon/>
                                        </IconButton>
                                    </GridItem>
                                    <GridItem xs={10} sm={12} md={4}>
                                      <TextField id={"ExpenditureTotalAmount"}
                                                 InputLabelProps={{shrink: true}}
                                                 fullWidth
                                                 value={this.calculateTotalIncomeExpenditureAmount(editedAssessment, "expenditure")}
                                                 InputProps={{
                                                     readOnly: true,
                                                     startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                 }}/>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}>
                                <GridContainer>
                                    <GridItem xs={10} sm={12} md={12}>
                                        <InputLabel shrink={true}>Debt</InputLabel>
                                    </GridItem>
                                    {this.renderIncomeExpenditure(editedAssessment, "debt", editing)}
                                    <GridItem xs={10} sm={12} md={6}>
                                        <IconButton aria-label="add"
                                                    size="small"
                                                    disabled={!editing}
                                                    onClick={() => this.addIncomeExpenditure("debt")}>
                                            <AddCircleOutlineOutlinedIcon/>
                                        </IconButton>
                                    </GridItem>
                                    <GridItem xs={10} sm={12} md={4}>
                                      <TextField id={"DebtTotalAmount"}
                                                 InputLabelProps={{shrink: true}}
                                                 fullWidth
                                                 value={this.calculateTotalIncomeExpenditureAmount(editedAssessment, "debt")}
                                                 InputProps={{
                                                     readOnly: true,
                                                     startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                 }}/>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={8}>
                                <TextField id="incomeExpenditureComments"
                                           label="Comments on financial assessment"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           multiline
                                           rows={3}
                                           rowsMax={Infinity}
                                           fullWidth
                                           value={editedAssessment.incomeExpenditureComments || ''}
                                           error={this.state.error.incomeExpenditureComments}
                                           helperText={this.state.error.incomeExpenditureComments}
                                           onChange={ev => this.changeValue("incomeExpenditureComments", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}/>
                            <GridItem xs={10} sm={12} md={4}>
                                <FormControl fullWidth>
                                  <InputLabel shrink={true}>Issue resolution</InputLabel>
                                    <FormGroup row={true}>
                                        <FormControlLabel
                                          control={<Checkbox id="resolved"
                                                             name="resolved"
                                                             disabled={!editing}
                                                             checked={editedAssessment?.issueResolution?.toLowerCase() === 'resolved'}
                                                             onChange={ (ev) => {
                                                                 if (ev.target.checked)
                                                                    this.changeValue("issueResolution", 'RESOLVED')}
                                                             } />}
                                          label="Resolved"/>
                                        <FormControlLabel
                                          control={<Checkbox id="partiallyResolved"
                                                             name="partiallyResolved"
                                                             disabled={!editing}
                                                             checked={editedAssessment?.issueResolution?.toLowerCase() === 'partially_resolved'}
                                                             onChange={ (ev) => {
                                                                 if (ev.target.checked)
                                                                    this.changeValue("issueResolution", 'PARTIALLY_RESOLVED')}
                                                             } />}
                                          label="Partially resolved"/>
                                        {this.state.error.issueResolution && (<FormHelperText error={this.state.error.issueResolution}>{this.state.error.issueResolution}</FormHelperText>)}
                                    </FormGroup>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                    <KeyboardDatePicker
                                        key={this.props.id + editing && 'edit' || ''}
                                        value={editedAssessment.predictedDateOfSuccess && parseDate(editedAssessment.predictedDateOfSuccess) || null}
                                        autoOk
                                        clearable
                                        label="Date of predicted successful outcome"
                                        variant="inline"
                                        onChange={(date) => this.changeValue("predictedDateOfSuccess", isValid(date) && format(date, 'dd-MM-yyyy') || date)}
                                        disablePast
                                        error={this.state.error.predictedDateOfSuccess}
                                        helperText={this.state.error.predictedDateOfSuccess}
                                        TextFieldComponent={!editing && ReadOnlyTextFieldComponent || TextFieldComponent}
                                        format="dd-MM-yyyy"/>
                                </MuiPickersUtilsProvider>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={1}>
                                <Tooltip classes={{tooltip: classes.tooltip}} title="Please use this date to inform how many parcel are allocated">
                                    <HelpOutlineIcon/>
                                </Tooltip>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={3}/>
                            <GridItem xs={10} sm={12} md={8}>
                                <TextField id="referredTo"
                                           label="Referred to"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           error={this.state.error.referredTo}
                                           value={editedAssessment.referredTo || ''}
                                           helperText={this.state.error.referredTo}
                                           onChange={ev => this.changeValue("referredTo", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}/>
                            <GridItem xs={10} sm={12} md={8}>
                                <TextField id="furtherComments"
                                           label="Further comments/Action plan"
                                           InputProps={{
                                               readOnly: !editing,
                                               endAdornment: (<InputAdornment position="end">
                                                   <Tooltip classes={{tooltip: classes.tooltip}}
                                                            title="It is important that an action plan here is created to ensure the guest is supported beyond just food support.">
                                                       <HelpOutlineIcon/>
                                                   </Tooltip>
                                               </InputAdornment>)
                                           }}
                                           InputLabelProps={{shrink: true}}
                                           multiline
                                           rows={3}
                                           rowsMax={Infinity}
                                           fullWidth
                                           value={editedAssessment.furtherComments || ''}
                                           error={this.state.error.furtherComments}
                                           helperText={this.state.error.furtherComments}
                                           onChange={ev => this.changeValue("furtherComments", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={4}/>
                            <GridItem xs={10} sm={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="status" shrink={true}>Further food aid</InputLabel>
                                    <Select id="status"
                                            name="status" disabled={!editing}
                                            value={editedAssessment.status || 'NONE'}
                                            IconComponent={() => <ArrowDropDown style={!editing && { display: "none" } || {}} />}
                                            error={this.state.error.status}
                                            onChange={ev => this.changeValue("status", ev.target.value, this.validateNumberOfParcels)}>
                                        <MenuItem value="NONE"></MenuItem>
                                        <MenuItem value="APPROVED">Approved</MenuItem>
                                        <MenuItem value="REJECTED">Rejected</MenuItem>
                                    </Select>
                                    <FormHelperText>{this.state.error.status}</FormHelperText>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <TextField id="numberOfParcels"
                                           label="Number of parcels"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           disabled={editedAssessment.status && editedAssessment.status === "REJECTED"}
                                           fullWidth
                                           value={editedAssessment.numberOfParcels}
                                           error={this.state.error.numberOfParcels}
                                           helperText={this.state.error.numberOfParcels}
                                           onChange={ev => this.changeValue("numberOfParcels", ev.target.value, this.validateNumberOfParcels)}
                                           type="number"/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="parcelFrequency" shrink={true}>Frequency of parcels</InputLabel>
                                    <Select id="parcelFrequency"
                                            name="parcelFrequency"
                                            disabled={!editing || !this.props.canEditFrequency}
                                            value={editedAssessment.parcelFrequency || 'MONTHLY'}
                                            IconComponent={() => <ArrowDropDown style={(!editing || !this.props.canEditFrequency) && { display: "none" } || {}} />}
                                            error={this.state.error.parcelFrequency}
                                            onChange={ev => this.changeValue("parcelFrequency", ev.target.value)}>
                                        <MenuItem value="MONTHLY">Monthly</MenuItem>
                                        <MenuItem value="WEEKLY">Weekly</MenuItem>
                                    </Select>
                                    <FormHelperText>{this.state.error.status}</FormHelperText>
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                    </form>
                </DialogContent>
                <DialogActions>
                    {this.props.id !== null && this.state.edit && (
                        <Button onClick={() => {
                            this.setState({
                                editedAssessment: {},
                                errors: {},
                                displayMessage: null,
                                edit: false
                            })
                        }} color="primary">
                            Cancel
                        </Button>
                    )}
                    {(!this.state.edit || this.props.readOnly || (this.props.id === null && this.props.referral)) && (
                        <Button onClick={() => {
                            this.setState({
                                editedAssessment: {},
                                errors: {},
                                displayMessage: null,
                                edit: false
                            })
                            this.props.onClose();
                        }} color="primary">
                            {this.props.id === null && 'Cancel' || 'Close'}
                        </Button>
                    )}
                    {(this.props.id === null && this.props.referral || !this.props.readOnly && this.state.edit) && (
                        <Button onClick={this.editFurtherAssessment} color="primary" autoFocus>
                            {this.props.id === null && 'Create' || 'Save'}
                        </Button>)}
                    {this.state.displayMessage && (
                        <FormHelperText error={true}>Please fix errors before saving</FormHelperText>)}
                </DialogActions>
            </Dialog>);
    }
}

export default withStyles(styles)(FurtherAssessmentMonthly);
